<!--
 * @Description: AKJERRT
 * @Date: 2022-07-11 17:35:05
 * @LastEditTime: 2022-07-13 15:04:14
 * @FilePath: \linkgap-front\src\views\account_statement\components\handler\toExamine.vue
-->
<template>
  <a-modal title="审核账单" width="50%" v-model="visible" :footer="null" :maskClosable="false">
    <a-form :form="res_data">
      <a-form-item label="审核状态" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
        <a-radio-group v-model="res_data.result">
          <a-radio :value="2"> 通过 </a-radio>
          <a-radio :value="3"> 拒绝 </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="审核说明" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
        <a-textarea :rows="6" placeholder="审核说明" v-model="res_data.comment"></a-textarea>
      </a-form-item>
    </a-form>
    <a-row type="flex" justify="end">
      <a-col>
        <a-button :loading="loading" type="primary" @click="toSubmit">提交</a-button>
        <a-button :loading="loading" @click="cancel">取消</a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import {auditTaskBatch} from "@/api/activiti";

export default {
  name: 'confirm',
  data() {
    return {
      handle: '',
      loading:false,
      res_data: {
        taskIds: [],
        result: 2,
        comment: '',
      },
      visible: false,
    }
  },
  methods: {
    init(row) {
      this.visible = true
      const vo = []
      row.forEach((e) => {
        vo.push(e.taskId)
      })
      this.res_data.taskIds = vo
      this.res_data.result = 2
    },
    toSubmit() {
      this.loading = true
      auditTaskBatch(this.res_data).then((res) => {
        if (res.code == 200) {
          this.$message.success('审核成功')
          this.$emit('reload')
          this.res_data = {}
        }else {
          this.$message.error(res.message)
        }
        this.visible = false
        this.loading = false
      })
    },
    cancel() {
      this.visible = false
    },
  },
}
</script>
